<template>
    <div class="users-table users-custom-table">
        <user-settings-slideout
            v-model="userSettingsOpened"
            :user="selectedUser"
            @refresh="onRefresh" />

        <!-- Delete User Dialog Start -->
        <styled-dialog v-model="deleteUserConfirmationDialogOpen">
            <template #heading>
                <h3>Delete User</h3>
            </template>
            <template v-if="deleteUserComplete">
                <p>
                    We have successfully deleted the user account for
                    {{ deleteUserConfirmationDialogUser.name }}
                </p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        @click="deleteUserConfirmationDialogOpen = false">
                        CLOSE
                    </styled-button>
                </div>
            </template>
            <template v-else>
                <p>
                    Are you sure you want to delete the user account for
                    {{ deleteUserConfirmationDialogUser.name }} ({{ deleteUserConfirmationDialogUser.email }})?
                    All of their data will be deleted and they will no longer be able to login.
                </p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        :loading="deleteUserLoading"
                        @click="deleteUser()">
                        DELETE USER
                    </styled-button>
                </div>
                <a
                    href="#"
                    class="red--text d-block mt-3"
                    @click="deleteUserConfirmationDialogOpen = false">
                    cancel
                </a>
            </template>
        </styled-dialog>
        <!-- Delete User Dialog End -->


        <!-- Add Dealers Dialog Start -->
        <styled-dialog
            v-model="addDealersDialogOpen"
            :width="700">
            <template #heading>
                <h3>Add Dealers</h3>
            </template>
            <template v-if="addDealersComplete">
                <p>
                    We have successfully add the selected dealers to
                    {{ addDealersDialogUser.name }}'s account
                </p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        @click="addDealersDialogOpen = false">
                        CLOSE
                    </styled-button>
                </div>
            </template>
            <template v-else>
                <p>
                    Select the dealers to you want to add to
                    {{ addDealersDialogUser.name }}'s account below
                </p>
                <multiple-dealer-field
                    v-model="addDealersDialogSelectedDealers"
                    :agency-id="addDealersDialogUser.agency ? addDealersDialogUser.agency.id : addDealersDialogUser.agency" />
                <div class="text-xs-center mt-5">
                    <styled-button
                        :loading="addDealersLoading"
                        :disabled="!addDealersDialogSelectedDealersValues.length"
                        @click="addDealers()">
                        ADD DEALERS
                    </styled-button>
                </div>
                <a
                    href="#"
                    class="red--text d-block mt-3"
                    @click="addDealersDialogOpen = false">
                    cancel
                </a>
            </template>
        </styled-dialog>
        <!-- Add Dealers Dialog End -->

        <styled-card class="mb-4">
            <template #action-buttons>
                <div
                    v-if="permissionCreateUser"
                    class="icon-button"
                    @click="$router.push({ name: 'users-new' })">
                    <icon
                        name="indicator-add"
                        size="15"
                        color="white" />
                    <span>ADD NEW USER</span>
                </div>
                <action-button
                    icon="refresh"
                    @click.prevent="onRefresh">
                    Refresh
                </action-button>
            </template>

            <custom-table
                ref="usersTable"
                :items="users"
                :fields="fields"
                :reset="reset"
                :loading="loading"
                :pagination="pagination"
                :rows-per-page="pagination.rowsPerPage"
                :total-items="pagination.meta.totalItems"
                :notification="notification"
                :notification-type="notificationType"
                :selected-filters="selectedFilters"
                :custom-filters="customFilters"
                backend-filtering
                has-filters
                hide-sort
                name="users"
                min-width="1200px"
                @update-pagination="updateUsers"
                @update-filtering="updateFiltering"
                @search="onSearch">
                <template #header-slot>
                    <!-- <div
                            v-if="userIsAdmin"
                            class="admin-only pa-2 ml-4">
                            <v-checkbox
                                v-model="allAgencies"
                                label="All agencies"
                                color="primary"
                                class="styled-checkbox" />
                        </div> -->
                    <div
                        v-if="selectedTags.length"
                        class="filters-wrapper">
                        <div class="filter-title">
                            Filtered by:
                        </div>
                        <div class="filter-applied">
                            <template v-if="selectedTags.length">
                                <div
                                    v-for="(tag, index) in selectedTags"
                                    :key="index"
                                    class="filter-single">
                                    <span>{{ tag }}</span>
                                    <div @click="clearTag(tag)">
                                        <icon
                                            name="close"
                                            color="#8f9ea6"
                                            size="10"
                                            @click="clearTag(tag)" />
                                    </div>
                                </div>
                            </template>
                            <div
                                class="filter-clear"
                                @click="clearAllSelections">
                                Clear all
                            </div>
                        </div>
                    </div>
                </template>
            </custom-table>
        </styled-card>
    </div>
</template>

<script>
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledCard from '@/components/globals/StyledCard';
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import MultipleDealerField from '@/components/globals/fields/MultipleDealerField';
import UserSettingsSlideout from '@/components/account-management/UserSettingsSlideout';
import ApiQuery from '@/api-query';
import { mapState, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import {
    PERMISSION_CREATE_USER,
    PERMISSION_UPDATE_AGENCY_USER,
} from '@/helpers/globals';

export default {
    name: 'CustomTableDemo',
    components: {
        CustomTable,
        StyledCard,
        Icon,
        ActionButton,
        StyledDialog,
        StyledButton,
        UserSettingsSlideout,
        MultipleDealerField
    },
    data() {
        return {
            //modals
            deleteUserConfirmationDialogOpen: false,
            deleteUserConfirmationDialogUser: {},
            deleteUserConfirmationDialogError: null,
            deleteUserComplete: false,
            deleteUserLoading: false,
            addDealersDialogOpen: false,
            addDealersDialogUser: {},
            addDealersDialogError: null,
            addDealersComplete: false,
            addDealersLoading: false,
            addDealersDialogSelectedDealers: [],

            //table
            columnPresets: [],
            reset: false,
            loading: true,
            allAgencies: false,
            fields: [
                {
                    id: 'id',
                    header: 'ID',
                    value: 'id',
                    align: 'left',
                    width: '9%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'name',
                    header: 'Username',
                    value: 'name',
                    align: 'left',
                    width: '21%',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                },
                {
                    id: 'email',
                    header: 'Email',
                    value: 'email',
                    align: 'left',
                    width: '26%',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'roles.data.0.display_name',
                    header: 'Role(s)',
                    value: 'roles.data.0.display_name',
                    align: 'left',
                    width: '12%',
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'dealers.name',
                    header: 'Account(s)',
                    value: 'dealers.name',
                    align: 'left',
                    width: '23%',
                    type: 'component',
                    filter: 'search',
                    component: () => import('@/components/users/UserDealers.vue'),
                },
                {
                    id: 'edit',
                    header: '',
                    value: '',
                    align: 'center',
                    width: '40px',
                    type: 'component',
                    component: () => import('@/components/users/UserActionButton.vue'),
                    callback: (value) => {
                        this.openUserSlideout(value);
                    },
                    props: {
                        icon: 'edit',
                    }
                },
            ],
            deleteUserIcon: {
                id: 'trash',
                header: '',
                value: '',
                align: 'center',
                width: '40px',
                type: 'component',
                component: () => import('@/components/users/UserActionButton.vue'),
                callback: (value) => {
                    this.openDeleteConfirmationDialog(value);
                },
                props: {
                    icon: 'indicator-error',
                }
            },
            users: [],
            raw: [],
            pagination: {
                rowsPerPage: 25,
                page: 1,
                sortBy: 'name',
                descending: false,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            },
            search: '',
            notification: '',
            notificationType: '',
            selectedFilters: {},
            customFilters: [{ 'roles.data.0.display_name': ['Admin', 'Agency Admin', 'Agency User', 'Onboarding', 'Client'] }],
            filters: [],
            selectedTags: [],

            //slideout
            selectedUser: {},
            userSettingsOpened: false,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
        permissionCreateUser() {
            return this.$store.getters.userHasPermission(PERMISSION_CREATE_USER);
        },
        permissionUpdateAgencyUser() {
            return this.$store.getters.userHasPermission(PERMISSION_UPDATE_AGENCY_USER);
        },
        addDealersDialogSelectedDealersValues() {
            return this.addDealersDialogSelectedDealers
                .filter(dealer => typeof dealer === 'object')
                    .map(dealer => {
                        return {
                            related_object_key: dealer.id
                        };
                    });
        }
    },
    watch: {
        allAgencies() {
            this.getUsers();
        },
        currentAgency() {
            this.getUsers();
        }
    },
    created() {
        this.$title = 'Users';
        if (this.permissionUpdateAgencyUser) {
            this.fields.push(this.deleteUserIcon);
        } else {
            this.fields.at(-1).props.icon = 'list';
        }
        this.getUsers();
    },
    methods: {
        clearAllSelections() {
            this.selectedFilters = {};
            this.filters = [];
            this.populateTags();
            this.getUsers();
        },
        clearTag(tag) {
            const currentTag = this.fields.find(field => field.header == tag);
            this.selectedFilters[currentTag.id] = [];
            this.populateTags();
            this.updateFiltering('', currentTag.id);
        },
        populateTags() {
            const tags = new Set();
            for (const filter in this.selectedFilters) {
                const currentFilter = this.fields.find(field => field.id == filter);
                if (this.selectedFilters[filter].length) {
                    tags.add(currentFilter.header);
                } else {
                    tags.delete(currentFilter.header);
                }
            }
            this.selectedTags = Array.from(tags);
        },
        updateFiltering(value, filter) {
            this.selectedFilters[filter] = value;
            const filters = [];
            Object.keys(this.selectedFilters).forEach(key => {
                if (this.selectedFilters[key] instanceof Array) {
                    if (filter === 'roles.data.0.display_name' && this.selectedFilters[key].length) {
                        const arrayFilter = {
                            column: 'roles.display_name',
                            operator: 'IN',
                            value: this.selectedFilters[key]
                        };
                        filters.push(arrayFilter);
                    }
                } else if (this.selectedFilters[key]) {
                    const stringFilter = {
                        column: key,
                        operator: 'like',
                        value: '%'+this.selectedFilters[key]+'%'
                    };
                    filters.push(stringFilter);
                }
            });
            this.filters = filters;
            this.populateTags();
            this.getUsers();
        },
        async onRefresh() {
            const restPagination = {
                rowsPerPage: 25,
                page: 1,
                sortBy: 'name',
                descending: true,
            };
            Object.assign(this.pagination, restPagination);
            this.$refs.usersTable.clearSearch();
            this.search = '';
            await this.getUsers();
        },
        onSearch: debounce(async function(search) {
            if (search) {
                this.search = search;
            } else {
                this.search = '';
            }
            await this.getUsers();
        }, 500),
        async updateUsers(pagination) {
            Object.assign(this.pagination, pagination);
            await this.getUsers();
        },
        async getUsers() {
            try {
                this.loading = true;
                const apiQuery = new ApiQuery('users');
                apiQuery
                    .setPaginationOptionsFromVuetifyDataTable(this.pagination)
                    .withRelationships('users.dealers', 'users.roles', 'users.agency')
                    .withFilters(this.filters)
                    .setSearchQuery(this.search);

                // Unless the user is an admin and has requsted all agencies always scope the query
                if (!this.allAgencies) {
                    apiQuery.withScope('users.agency_equals', {
                        id: this.currentAgency.id
                    });
                }

                const response = await apiQuery.get();
                this.users = response.data.data;
                this.raw = response.data;

                //setting pagination meta
                const newpagination = { ...this.pagination };
                newpagination.meta.totalItems = this.raw.meta.total;
                newpagination.meta.current_page = this.raw.meta.current_page;
                newpagination.meta.last_page = this.raw.meta.last_page;
                newpagination.meta.to = this.raw.meta.to;
                newpagination.meta.from = this.raw.meta.from;

                this.pagination = newpagination; // setting new pagination and triggering 'watch' to re-render table-component
                this.loading = false;
            } catch (error) {
                console.log('Error retrieving users', error);
                this.loading = false;
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        },
        scrollToTop() {
            this.$scrollTo(
                this.$refs.usersTable,
                500,
                {
                    offset: 100
                }
            );
        },
        openDeleteConfirmationDialog(user) {
            this.deleteUserComplete = false;
            this.deleteUserConfirmationDialogOpen = true;
            this.deleteUserConfirmationDialogUser = user;
        },
        async deleteUser() {
            try {
                this.deleteUserLoading = true;
                await this.$http.delete(`users/${this.deleteUserConfirmationDialogUser.id}`);
                await this.getUsers();
                this.deleteUserComplete = true;
            } catch (error) {
                console.error('Error deleting user', error);
                this.deleteUserConfirmationDialogError = 'Error deleting users';
            } finally {
                this.deleteUserLoading = false;
            }
        },
        openAddDealersDialog(user) {
            this.addDealersComplete = false;
            this.addDealersDialogOpen = true;
            this.addDealersDialogUser = user;
        },
        openUserSlideout(user) {
            this.userSettingsOpened = true;
            this.selectedUser = user;
        },
        async addDealers() {
            try {
                this.addDealersLoading = true;
                const config = {
                    related: this.addDealersDialogSelectedDealersValues
                };
                await this.$http.post(`users/${this.addDealersDialogUser.id}/dealers/attach`, config);
                await this.getUsers();
                this.addDealersComplete = true;
                this.addDealersDialogSelectedDealers = [];
            } catch (error) {
                console.error('Error deleting users', error);
                this.addDealersDialogError = 'Error deleting users';
            } finally {
                this.addDealersLoading = false;
            }
        }
    },
};
</script>

<style lang="scss">
.users-custom-table{
    .heading-wrapper{
        display: flex;
        align-items: center;
    }
    .styled-card {
        position: relative;
        overflow: visible;
    }
    .header-cell .filter {
        color: #6D797F !important;
        [data-lastpass-icon-root] {
            display: none;
        }
    }
    .header-cell.active .filter{
        color: #050c11 !important;
    }
    .filters-wrapper {
        margin-top: -20px;
        display: inline-block;
        margin-left: 30px;
    }
    .filter-title{
        color: #00A4EB;
        margin-bottom: 5px;
    }
    .filter-applied{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    .filter-single{
        display: flex;
        align-items: center;
        background: #D7F0FB;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        span{
            margin-right: 15px;
        }
        div{
            display: flex;
            align-items: center;
            margin-top:2px;
            cursor: pointer;
        }
    }
    .filter-clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
    .table-header > td:nth-of-type(2) .filter{
        max-width: 220px;
    }
    .table-header > td:nth-of-type(4) .filter{
        max-width: 110px;
    }
    .table-header > td:nth-of-type(5) .filter{
        max-width: 220px;
    }
}
</style>
