<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <!-- Password Reset Dialog Start -->
        <styled-dialog v-model="passwordResetConfirmationDialogOpen">
            <template #heading>
                <h3>Send Password Reset</h3>
            </template>
            <template v-if="passwordResetComplete">
                <p>We have successfully sent reset instructions to {{ user.email }}</p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        @click="passwordResetConfirmationDialogOpen = false">
                        CLOSE
                    </styled-button>
                </div>
            </template>
            <template v-else>
                <p>Are you sure you want to send a password reset to {{ user.name }} ({{ user.email }})?</p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        :loading="passwordResetLoading"
                        :disabled="passwordResetLoading"
                        @click="sendPasswordReset()">
                        SEND PASSWORD RESET
                    </styled-button>
                </div>
                <a
                    href="#"
                    class="red--text d-block mt-3"
                    @click="passwordResetConfirmationDialogOpen = false">
                    cancel
                </a>
                <div
                    v-if="passwordResetError"
                    class="error-message mt-4 text-center">
                    {{ passwordResetError }}
                </div>
            </template>
        </styled-dialog>
        <!-- Password Reset Dialog End -->

        <!-- Delete User Dialog Start -->
        <styled-dialog v-model="deleteUserConfirmationDialogOpen">
            <template #heading>
                <h3>Delete User</h3>
            </template>
            <template v-if="deleteUserComplete">
                <p>
                    We have successfully deleted the user account for
                    {{ user.name }}
                </p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        @click="closeAfterDeleting">
                        CLOSE
                    </styled-button>
                </div>
            </template>
            <template v-else>
                <p>
                    Are you sure you want to delete the user account for
                    {{ user.name }} ({{ user.email }})?
                    All of their data will be deleted and they will no longer be able to login.
                </p>
                <div class="text-xs-center mt-5">
                    <styled-button
                        :loading="deleteUserLoading"
                        :disabled="deleteUserLoading"
                        @click="deleteUser">
                        DELETE USER
                    </styled-button>
                </div>
                <a
                    href="#"
                    class="red--text d-block mt-3"
                    @click="deleteUserConfirmationDialogOpen = false">
                    cancel
                </a>
                <div
                    v-if="deleteUserError"
                    class="error-message mt-4 text-center">
                    {{ deleteUserError }}
                </div>
            </template>
        </styled-dialog>
        <!-- Delete User Dialog End -->

        <div class="pa-5">
            <h2 class="mb-3">
                User Settings
            </h2>
            <v-form
                ref="form"
                v-model="valid">
                <v-text-field
                    v-model="userName"
                    :rules="requiredRules"
                    class="styled-field mb-4"
                    label="USER NAME"
                    :disabled="!permissionUpdateAgencyUser"
                    required />
                <v-text-field
                    v-model="userEmail"
                    :rules="[...emailRules, ...requiredRules]"
                    class="styled-field mb-4"
                    label="Email"
                    :disabled="!permissionUpdateAgencyUser"
                    required />
                <v-select
                    v-model="userRole"
                    :items="roles"
                    class="styled-field mb-5"
                    item-value="key"
                    item-text="display_name"
                    return-object
                    label="User Role"
                    :disabled="!permissionUpdateAgencyUser"
                    required />
                <v-layout
                    v-if="permissionUpdateAgencyUser"
                    class="form-button"
                    row
                    justify-center
                    align-center>
                    <v-btn
                        flat
                        :disabled="!valid || loading"
                        :loading="loading"
                        color="white"
                        @click="updateUser">
                        SAVE
                    </v-btn>
                </v-layout>
                <div
                    v-if="error"
                    class="error-message mt-4 text-center">
                    {{ error }}
                </div>
            </v-form>
            <template v-if="permissionUpdateAgencyUser">
                <h2 class="mb-3 mt-5">
                    Password Reset
                </h2>
                <v-layout
                    class="form-button"
                    row
                    justify-center
                    align-center>
                    <v-btn
                        flat
                        color="white"
                        @click="openPasswordResetConfirmationDialog">
                        SEND PASSWORD RESET
                    </v-btn>
                </v-layout>
                <template v-if="user.id !== currentUser.id">
                    <h2 class="mt-5">
                        Delete User
                    </h2>
                    <v-layout
                        class="form-button delete"
                        row
                        justify-center
                        align-center>
                        <v-btn
                            flat
                            color="white"
                            @click="openDeleteConfirmationDialog">
                            DELETE THIS USER
                        </v-btn>
                    </v-layout>
                </template>
            </template>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import { requiredRules, emailRules } from '@/helpers/validationRules';
import { mapState } from 'vuex';
import { PERMISSION_UPDATE_AGENCY_USER } from '@/helpers/globals';

export default {
    components: {
        StyledSlideout,
        StyledDialog,
        StyledButton
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            passwordResetConfirmationDialogOpen: false,
            passwordResetComplete: false,
            passwordResetLoading: false,
            passwordResetError: false,
            deleteUserConfirmationDialogOpen: false,
            deleteUserConfirmationDialogUser: {},
            deleteUserError: null,
            deleteUserComplete: false,
            deleteUserLoading: false,
            valid: false,
            userName: '',
            userEmail: '',
            userRole: '',
            userRoles: [],
            error: '',
            loading: false,
            roles: [
                {
                    name: 'admin',
                    display_name: 'Admin'
                },
                {
                    name: 'performance_manager',
                    display_name: 'Agency User'
                },
                {
                    name: 'onboarding',
                    display_name: 'Onboarding'
                },
                {
                    name: 'client',
                    display_name: 'Client'
                },
                {
                    name: 'agency_dashboard',
                    display_name: 'Agency Admin'
                }
            ],
            requiredRules,
            emailRules
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.user,
        }),
        permissionUpdateAgencyUser() {
            return this.$store.getters.userHasPermission(PERMISSION_UPDATE_AGENCY_USER);
        }
    },
    watch: {
        value(newValue) {
            if (newValue === true) {
                this.userName = this.user.name;
                this.userEmail = this.user.email;
                this.roles.forEach(role => {
                    // fallback for old super_admin role
                    if (this.user.roles.data[0].name === 'super_admin') {
                        this.userRole = {
                            name: 'admin',
                            display_name: 'Admin'
                        };
                    } else if (role.name === this.user.roles.data[0].name) {
                        this.userRole = role;
                    }
                });
            }
        }
    },
    methods: {
        async updateUser() {
            try {
                this.loading = true;
                const userData = {
                    id: this.user.id,
                    name: this.userName,
                    email: this.userEmail,
                    role: this.userRole.name
                };
                const response = await this.$apiRepository.updateUser(userData);
                const updatedUser = response.data.data.id ?? null;
                if (updatedUser) {
                    this.$flash('User has been successfully updated!', 'green');
                    this.$emit('input', false);
                    this.$emit('refresh');
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        openPasswordResetConfirmationDialog() {
            this.passwordResetComplete = false;
            this.passwordResetConfirmationDialogOpen = true;
        },
        async sendPasswordReset() {
            try {
                this.passwordResetLoading = true;
                await this.$apiRepository.sendPasswordReset(this.user.email);
                this.passwordResetComplete = true;
            } catch (error) {
                console.error('Error sending password reset', error);
                this.passwordResetError = error;
            } finally {
                this.passwordResetLoading = false;
            }
        },
        openDeleteConfirmationDialog() {
            this.deleteUserComplete = false;
            this.deleteUserConfirmationDialogOpen = true;
        },
        async deleteUser() {
            try {
                this.deleteUserLoading = true;
                await this.$http.delete(`users/${this.user.id}`);
                this.deleteUserComplete = true;
            } catch (error) {
                console.log(error);
                this.deleteUserError = 'Error deleting user';
            } finally {
                this.deleteUserLoading = false;
            }
        },
        closeAfterDeleting() {
            this.deleteUserConfirmationDialogOpen = false;
            this.$emit('refresh');
            this.$emit('input', false);
        }
    }
};
</script>

<style scoped lang="scss">
.error-message {
    color: $error-500;
}
.form-button {
    button {
        padding: 20px 25px;
        font-size: 16px;
    }
    &.delete button {
        background-color: $error-500;
    }
}
</style>